<template>
    <!-- Footer Start -->
    <footer class="footer">
        <div class="container-fluid">
            <div class="row">
                <div class="col-md-6">
                </div>
                <div class="col-md-6">
                    <div class="text-md-right footer-links d-none d-md-block">
                        <span>Powered by: <a href="https://www.borlaug.ws/"
                                             target="_blank">Borlaug Web Services ™</a></span>
                    </div>
                </div>
            </div>
        </div>
    </footer>
    <!-- end Footer -->
</template>

<script>
    export default {
        name: "WorkspaceFooter",
        data() {
            return {
                version: ""
            };
        },
    }
</script>

<style scoped>
</style>
<template>
    <div class="wrapper" data-layout="topnav">
        <div class="content-page">
            <div class="content">
                <workspace-topbar></workspace-topbar>
                <router-view></router-view>
            </div>
            <workspace-footer></workspace-footer>
        </div>
        <!-- Loader -->
        <loading-screen :is-loading="isLoading"/>
    </div>
</template>

<script>
    import WorkspaceTopbar from "./WorkspaceTopbar";
    import WorkspaceFooter from "./WorkspaceFooter";
    import LoadingScreen from "./LoadingScreen";
    import EventBus from "@/event-bus";

    export default {
        name: 'app',
        components: {WorkspaceFooter, WorkspaceTopbar, LoadingScreen},
        data() {
            return {
                isLoading: false
            };
        },
        mounted() {
            EventBus.$on('closeLoader', () => {
                this.isLoading = false;
            });
            EventBus.$on('openLoader', () => {
                this.isLoading = true;
            });
        },
    }
</script>

<style lang="scss">
</style>

<template>
  <div>
    <!-- Topbar Start -->
    <div class="navbar-custom topnav-navbar topnav-navbar-dark" style="background-color: rgb(0 0 0);">
      <div class="container-fluid">

        <!-- LOGO -->
        <router-link :to="{name: 'dashboard'}" class="topnav-logo">
          <span class="topnav-logo-lg">
            <img alt="" height="40" src="../../assets/images/logo-white.svg">
          </span>
          <span class="topnav-logo-sm">
            <img alt="" height="35" src="../../assets/images/logo-symbol.svg">
          </span>
        </router-link>

        <ul class="list-unstyled topbar-right-menu float-right mb-0">

          <li class="dropdown notification-list d-lg-none">
            <div class="dropdown-menu dropdown-menu-animated dropdown-lg p-0">
              <form class="p-3">
                <input aria-label="Recipient's username" class="form-control" placeholder="Search ..." type="text">
              </form>
            </div>
          </li>

          <li class="dropdown notification-list">
            <a aria-expanded="false" aria-haspopup="true" class="nav-link dropdown-toggle nav-user arrow-none mr-0" data-toggle="dropdown" href="#" id="topbar-userdrop" role="button">
              <span class="account-user-avatar">
                <img alt="user-image" class="rounded-circle" src="../../assets/images/users/avatar-1.svg">
              </span>
              <span>
                <span v-if="currentUser" class="account-user-name">{{currentUser.name}}</span>
                <span class="account-position">STIL Member</span>
              </span>
            </a>
            <div aria-labelledby="topbar-userdrop" class="dropdown-menu dropdown-menu-right dropdown-menu-animated topbar-dropdown-menu profile-dropdown">
              <!-- item-->
              <div class=" dropdown-header noti-title">
                <h6 class="text-overflow m-0">Welcome !</h6>
              </div>

              <!-- item-->
              <router-link :to="{name: 'user-profile'}" class="dropdown-item notify-item">
                <i class="mdi mdi-account-circle mr-1"></i>
                <span>My Account</span>
              </router-link>

              <!-- item-->
              <router-link :to="{name: 'changepassword'}" class="dropdown-item notify-item">
                <i class="mdi mdi-lock-outline mr-1"></i>
                <span>Change Password</span>
              </router-link>

              <!-- item-->
              <a @click="handleLogout" class="dropdown-item notify-item" href="javascript:void(0);">
                <i class="mdi mdi-logout mr-1"></i>
                <span>Logout</span>
              </a>

            </div>
          </li>

        </ul>
        <a class="navbar-toggle" data-target="#topnav-menu-content" data-toggle="collapse">
          <div class="lines">
            <span></span>
            <span></span>
            <span></span>
          </div>
        </a>
      </div>
    </div>
    <!-- end Topbar -->
    <workspace-menubar></workspace-menubar>
  </div>
</template>

<script>
import WorkspaceMenubar from "./WorkspaceMenubar";
import { mapGetters } from "vuex";

export default {
  name: "WorkspaceTopbar",
  components: { WorkspaceMenubar },
  computed: {
    ...mapGetters(["currentUser"])
  },
  methods: {
    handleLogout() {
      this.$store.commit("LOGOUT");
      this.$router.replace("/auth/login");
    },
  }
}
</script>

<style scoped>
</style>

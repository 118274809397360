<template>
    <div class="topnav shadow-sm">
        <div class="container-fluid">
            <nav class="navbar navbar-light navbar-expand-lg topnav-menu">

                <div class="collapse navbar-collapse" id="topnav-menu-content">
                    <ul class="navbar-nav">
                        <li class="nav-item dropdown">
                            <router-link :to="{name: 'dashboard'}" aria-expanded="false" aria-haspopup="true"
                                         class="nav-link dropdown-toggle arrow-none" data-toggle="dropdown">
                                <i class="uil-dashboard mr-1"></i>Dashboard
                                <div class="arrow-down"></div>
                            </router-link>
                        </li>
                        <li class="nav-item dropdown">
                            <router-link :to="{name: 'consignments'}" aria-expanded="false"
                                         aria-haspopup="true" class="nav-link dropdown-toggle arrow-none"
                                         data-toggle="dropdown"><i class="uil-apps mr-1"></i>Consignments
                                <div class="arrow-down"></div>
                            </router-link>
                        </li>
                        <li class="nav-item dropdown">
                            <router-link :to="{name: 'batches'}" aria-expanded="false"
                                         aria-haspopup="true" class="nav-link dropdown-toggle arrow-none"
                                         data-toggle="dropdown"><i class="uil-copy-alt mr-1"></i>Batches
                                <div class="arrow-down"></div>
                            </router-link>
                            <div class="dropdown-menu" aria-labelledby="topnav-dashboards">
                                <router-link :to="{name: 'batches'}" class="dropdown-item">List</router-link>
                                <router-link :to="{name: 'batch-report'}" class="dropdown-item">Report</router-link>
                            </div>
                        </li>
                    </ul>
                </div>
            </nav>
        </div>
    </div>
</template>

<script>
    export default {
        name: "WorkspaceMenubar"
    }
</script>

<style scoped>

</style>
